import React, { useEffect, FC } from "react"
import { navigate } from "gatsby"
import Splash from "../../content/assets/splash.png"

const Homepage: FC = () => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Redirect after 5 seconds
      navigate("http://inovapayroll.com/")
    }, 5000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: "2rem",
      }}
    >
      <img src={Splash} />
    </div>
  )
}

export default Homepage
